import React from 'react';
import PropTypes from 'prop-types';

export const BtmImportantDates = ({ dates }) => (
  <div className="btm_important_dates">
    <div className="btm_important_dates_list">
      {dates.map(date => (
        <div
          key={date.title + date.descrtiption}
          className="btm_important_date_card"
        >
          <p className="btm_important_date_card_title">{date.title}</p>
          <p className="btm_important_date_card_description">
            {date.description}
          </p>
        </div>
      ))}
    </div>
  </div>
);

BtmImportantDates.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      descrtiption: PropTypes.string
    })
  ).isRequired
};
